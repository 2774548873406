<template>
	<div
		class="full-height pa-10-20"
	>
		<h6>{{ program.name }}</h6>

		<Search
			v-if="search"
			class="mt-10 box"
			:search="search"
			:option="search_option"

			@reset="reset"
			@click="getSearch"
			@toItem="toItem"
			@setSearchDateType="setSearchDateType"
		>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.categoryIdx"
				@change="getSearch(1)"
			>
				<option value="">문의구분</option>
				<option
					v-for="add in category"
					:key="'type_' + add.idx"
					:value="add.idx"
				>{{ add.name }}</option>
			</select>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.searchState"
				@change="getSearch(1)"
			>
				<option
					v-for="add in codes.qna_status"
					:key="'status_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
		</Search>

		<div
			class="mt-10 box-ddd top-line-identify"
		>
			<div class=" bg-white justify-space-between">
				<div class="pa-20 flex-1 bg-icon-round-question border-right">
					<div class="color-identify">접수</div>
					<div class="mt-10 font-weight-bold size-px-16"> {{ summary.waiting | makeComma}}건</div>
				</div>

				<div class="pa-20 flex-1 bg-icon-round-papermulti border-right">
					<div class="color-identify">처리중</div>
					<div class="mt-10 font-weight-bold size-px-16"> {{ summary.processing | makeComma}}건</div>
				</div>

				<div class="pa-20 flex-1 bg-icon-round-answer">
					<div class="color-identify">답변완료</div>
					<div class="mt-10 font-weight-bold size-px-16"> {{ summary.completed | makeComma}}건</div>
				</div>
			</div>
		</div>

		<div class="mt-10 pa-10 bg-white flex-column overflow-y-auto">

			<div class="justify-space-between">

				<div class="line-height-200">총 <span class="font-weight-bold">{{ search.total_count | makeComma }}</span> 건</div>
				<select
					class="pa-5-10 mr-10 size-px-12"
					v-model="search.size"
					@change="getSearch()"
				>
					<option
						v-for="cnt in codes.list_cnt"
						:key="'cnt_' + cnt"
						:value="cnt"
					>{{ cnt }} 건씩 보기</option>
				</select>
			</div>

			<table
				v-if="items.length > 0"
				class="mt-10 table table-even top-line-identify"
			>
				<colgroup>
					<col width="80px" />
					<col width="120px" />
					<col width="auto" />
					<col width="150px" />
					<col width="150px" />

					<col width="100px" />
					<col width="120px" />
					<col width="120px" />
					<col width="180px" />
				</colgroup>
				<thead>
					<tr>
						<th>NO</th>
						<th>문의구분</th>
						<th>제목</th>
						<th>작성자</th>
						<th>답변자</th>

						<th>처리상태</th>
						<th>등록일</th>
						<th>답변일</th>
						<th>상세</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="(item, index) in item_list"
						:key="'list_' + index"
					>
						<td>{{ (10 * (search.page ? search.page - 1 : '0')) + index + 1 }}</td>
						<td>{{ item.categoryType }}</td>
						<td class="text-left">{{ item.title }}</td>
						<td>{{ item.writer }}</td>
						<td>{{ item.answerAuthor }}</td>

						<td>{{ item.state_name }}</td>
						<td>{{ item.regDate | transDate }}</td>
						<td>{{ item.answerDate | transDate }}</td>
						<td>
							<button
								class="bg-identify pa-5-10 size-px-12"
								@click="toDetail(item)"
							>상세보기</button>
						</td>
					</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center mt-10 top-line-identify"
			>
				<div class="text-center pa-20">
					<v-icon
						class="size-px-48 color-icon"
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>
		</div>

        <Pagination
            :program="program"
            :align="'center'"
            :options="search"

            class="mt-auto"
            @click="getSearch"
        ></Pagination>
	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";

export default {
	name: 'QnaList',
	components: { Search, Pagination},
	data: function(){
		return {
			program: {
				name: '1:1문의'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: this.$route.query.page ? this.$route.query.page : 1
				,total_count: 0
				,size: this.$route.query.size ? this.$route.query.size : 10
				,search_type: this.$route.query.search_type ? this.$route.query.search_type : ''
				,search_value: this.$route.query.search_value ? this.$route.query.search_value : ''
				,categoryIdx: this.$route.query.categoryIdx ? this.$route.query.categoryIdx : ''
				,searchState: this.$route.query.searchState ? this.$route.query.searchState : ''
				,keyword: this.$route.query.keyword ? this.$route.query.keyword : ''
				,word: this.$route.query.word ? this.$route.query.word : ''
				,wordCondition: this.$route.query.wordCondition ? this.$route.query.wordCondition : ''
				,sDate: this.$route.query.sDate ? this.$route.query.sDate : ''
				,eDate: this.$route.query.eDate ? this.$route.query.eDate : ''
				,search_date_type: this.$route.query.search_date_type ? this.$route.query.search_date_type : 'weekly'

			}
			,category: []
			,search_option: {
				sDate: true
				,eDate: true
				,is_search_date_type: true
				,search_type: [
					{ name: '제목+내용', column: '0'}
					, { name: '제목', column: '1'}
					, { name: '내용', column: '2'}
					, { name: '작성자', column: '3'}
					, { name: '답변자', column: '4'}
				]
			}
			,items: []
			,item: {}
			,summary: {}
		}
	}
	,computed: {
		item_list: function(){

			let self = this

			return this.items.filter(function(item){

				for(let i = 0; i < self.codes.qna_type.length; i++){
					if(item.categoryType == self.codes.qna_type[i].code){
						item.categoryType_name = self.codes.qna_type[i].name
						break
					}
				}

				for(let i = 0; i < self.codes.qna_status.length; i++){
					if(item.state == self.codes.qna_status[i].code){
						item.state_name = self.codes.qna_status[i].name
						break
					}
				}

				item.idx = item.qnaIdx

				return item
			})
		}
	}
	,methods: {
		getData: async function(){
			this.search.word = this.search.keyword
			this.search.wordCondition = this.search.search_type
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'qna/'
					,data: this.search
				})

				if(result.success){
					this.items = result.data.tableList.data
					this.search.total_count = result.data.tableList.totalCount
					this.summary.waiting = result.data.waiting.count
					this.summary.completed = result.data.completed.count
					this.summary.processing = result.data.processing.count
					localStorage.setItem('query', JSON.stringify(this.search))
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,toDetail: function(item){
			this.$layout.push( { name: 'QnaDetail', params: { idx: item.idx }, not_query: true})
		}
		,getSearch: function(page){

			if(page){
				this.search.page = page
			}

			this.getData()
		}
		,toItem: function(){
			this.$layout.push( { name: 'NoticeItem'})
		}
		,getCategory: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'qna/category'
				})

				if(result.success){
					this.category = result.data
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,setSearchDateType: function(type){
			this.search.search_date_type = type
		}
		,reset: function(){
			this.search = {
				page: 1
				,size: 10
				,search_type: ''
				,search_value: ''
				,categoryIdx: ''
				,searchState: ''
				,total_count: 0
				,keyword: ''
				,word: ''
				,wordCondition: ''
				,sDate: this.date.getLastDate(this.date.getToday('-'), 7,'-')
				,eDate: this.date.getToday('-')
				,search_date_type: 'weekly'
			}

			this.getData()
		}
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
		this.getData()
		this.getCategory()
	}
	,watch: {
		'search.search_date_type': {
			immediate: true
			, handler: function(call){
                if (!this.$layout) {
                    this.$layout = this.$root.$children[0].$children[0]
                    this.user = this.$layout.user
                }
				let today = this.date.getToday('-')
				this.search.eDate = today
				switch(call){
					case 'today':
						this.search.sDate = today

						this.getData()
						break
					case 'weekly':
						this.search.sDate = this.date.getLastDate(today, 7, '-')
						this.getData()
						break
					case 'month':
						this.search.sDate = this.date.getLastDate(today, 30, '-')
						this.getData()
						break
					case '3':
						this.search.sDate = this.date.getLastDate(today, 90, '-')
						break
					case '6':
						this.search.sDate = this.date.getLastDate(today, 180, '-')
						break
				}
			}
		}
	}
}
</script>

<style>
	.bg-icon-round-question { background: url('../../assets/images/icon/icon-round-question.svg') no-repeat 10px center; padding-left: 70px;}
	.bg-icon-round-papermulti { background: url('../../assets/images/icon/icon-round-papermulti.svg') no-repeat 10px center; padding-left: 70px;}
	.bg-icon-round-answer { background: url('../../assets/images/icon/icon-round-answer.svg') no-repeat 10px center; padding-left: 70px;}
</style>